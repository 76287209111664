import React from 'react';
import moment from 'moment';

import calendarLight from '../assets/calendar.svg';
import checkCircle from '../assets/check-circle.svg';
import clock from '../assets/clock-small.svg';
import userIcon from '../assets/user-small.svg';
import nightIcon from '../assets/night.svg';
import homeDark from '../assets/home-dark.svg';

import { CalendarPMS, PropertyList, ReservationHoverInfoResponse } from '../../api/type';

type ReservationTooltipProps = {
  booking: CalendarPMS | undefined;
  property: PropertyList | undefined;
  hoverInfo: ReservationHoverInfoResponse | null;
  loadingHoverInfo: boolean;
  onClose: () => void;
};

const ReservationTooltip: React.FC<ReservationTooltipProps> = ({
  booking,
  property,
  hoverInfo,
  loadingHoverInfo,
  onClose,
}) => {
  if (!booking?.reservationPMS) {
    return null;
  }

  const { reservationPMS } = booking;

  return (
    <div className="tooltip" onClick={onClose}>
      <div className="tooltip-content">
        <div className="reservation-tooltip-container">
          <div className="reservation-tooltip-info">
            <div className="reservation-icon">
              <img src={calendarLight} alt="Calendar Icon" />
            </div>
            <div className="reservation-value">
              Reservation - {reservationPMS.otaName === 'Manual Block' &&
                reservationPMS.guestFullName === 'Manual Block Pair Booking'
                ? 'Paired Booking'
                : reservationPMS.otaName}
            </div>
          </div>
          <div className="reservation-tooltip-info">
            <div className="confirmed-icon">
              <img src={checkCircle} alt="Calendar Icon" />
            </div>
            <div className="reservation-value confirmed">
              {reservationPMS.reservationStatus}
            </div>
          </div>
        </div>

        <div className="reservation-info">
          <div className="reservation-container">
            <div className="reservation-dates">
              <p>
                {moment(reservationPMS.arrivalDate).utc().format('MMM D YYYY')} -{' '}
                {moment(reservationPMS.departureDate).utc().format('MMM D YYYY')}
              </p>
              <div className="time-info">
                <span className="mr-3">
                  <img src={clock} alt="Clock Icon" /> 03:00 PM
                </span>
                <span>
                  <img src={clock} alt="Clock Icon" /> 10:00 AM
                </span>
              </div>
            </div>

            {reservationPMS.otaName !== 'Manual Block' &&
              reservationPMS.otaName !== 'Advance Notice' &&
              reservationPMS.otaName !== 'After Block' &&
              reservationPMS.otaName !== 'Before Block' &&
              reservationPMS.otaName !== 'Rolling Window' &&
              reservationPMS.otaName !== 'Rolling window' && (
                <>
                  <div className="reservation-tooltip-info">
                    <div className="reservation-icon">
                      <img src={nightIcon} alt="Half Moon Icon" className="night-icon" />
                    </div>
                    <div className="reservation-value">
                      {reservationPMS.totalDays} Days {reservationPMS.totalNights} Nights
                    </div>
                  </div>
                  <div className="reservation-tooltip-info">
                    <div className="reservation-icon">
                      <img src={userIcon} alt="User Small" className="user-icon" />
                    </div>
                    <div className="reservation-value">
                      {reservationPMS.totalGuests} Guests
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>

        <div className="property-dark-row">
          <div className="property-dark">
            <div className={property?.image ? 'listing-image' : 'listing-image-none'}>
              <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
            </div>
            <div>
              <h3>{property?.name}</h3>
              <p>{property?.address}</p>
            </div>
          </div>
        </div>

        {/* Amounts section */}
        {reservationPMS.otaName !== 'Manual Block' &&
          reservationPMS.otaName !== 'Advance Notice' &&
          reservationPMS.otaName !== 'After Block' &&
          reservationPMS.otaName !== 'Before Block' &&
          reservationPMS.otaName !== 'Rolling Window' &&
          reservationPMS.otaName !== 'Rolling window' && (
            <div className="amounts-container">
              <div className="amounts">
                <div>
                  {loadingHoverInfo ? (
                    <div className="loading-spinner-container">
                      <div className="spinner-tooltip">
                        <div className="double-bounce1" />
                        <div className="double-bounce2" />
                      </div>
                    </div>
                  ) : (
                    <p className="amount-value due">
                      ${hoverInfo?.data?.balanceDue ? hoverInfo.data.balanceDue.toFixed(2) : "0.00"}
                    </p>
                  )}
                  <p className="amount-label">Balance Due</p>
                </div>
                <div>
                  {loadingHoverInfo ? (
                    <div className="loading-spinner-container">
                      <div className="spinner-tooltip">
                        <div className="double-bounce1" />
                        <div className="double-bounce2" />
                      </div>
                    </div>
                  ) : (
                    <p className="amount-value">
                      ${hoverInfo?.data?.totalPaid ? hoverInfo.data.totalPaid.toFixed(2) : "0.00"}
                    </p>
                  )}
                  <p className="amount-label">Paid</p>
                </div>
                <div>
                  {loadingHoverInfo ? (
                    <div className="loading-spinner-container">
                      <div className="spinner-tooltip">
                        <div className="double-bounce1" />
                        <div className="double-bounce2" />
                      </div>
                    </div>
                  ) : (
                    <p className="amount-value">
                      ${hoverInfo?.data?.payout ? hoverInfo.data.payout.toFixed(2) : "0.00"}
                    </p>
                  )}
                  <p className="amount-label">Payout</p>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ReservationTooltip;
