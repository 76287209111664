import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import MultiViewCalendarApp from "./components/MultiViewCalendarApp";
import SingleViewCalendarApp from "./components/SingleViewCalendarApp";
import { authenticateimportantOrgId } from "./api/request";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Widget from "./components/widget/Widget";

export const ImportantOrgContext = createContext<{
  importantOrgId: string;
  userId: string;
  userType: string;
}>({
  importantOrgId: "",
  userId: "",
  userType: "",
});

const App: React.FC = () => {
  const [importantOrgId, setImportantOrgId] = useState("");
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");

  const checkAuth = async (token: string, userId: string, importantOrgId: string) => {
    try {

      const isAuthenticated: boolean = await authenticateimportantOrgId(token, userId, importantOrgId);
      // const isAuthenticated: boolean = true;
      console.log("Authentication Status: " + isAuthenticated);
      if (isAuthenticated) {
        setImportantOrgId(importantOrgId);
      }
    } catch (error) {
      console.error("Authentication Error: ", error);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const importantOrgId: string = params.get("importantOrgId") || "";
    const userId: string = params.get("userId") || "";
    const token: string = params.get("token") || "";
    const userType: string = params.get("userType") || "";
    setUserId(userId);
    setUserType(userType);
    checkAuth(token, userId, importantOrgId);
  }, []);

  return (
    <div className="App">
      <ImportantOrgContext.Provider value={{ importantOrgId, userId, userType }}>
        <Router>
          <Routes>
            <Route path="/widget" element={<Widget />} />
            <Route path="/" element={importantOrgId === "" ? <p>Loading...</p> : <MultiViewCalendarApp />} />
            <Route
              path="/single-view"
              element={<SingleViewCalendarApp />}
            />
          </Routes>
        </Router>
      </ImportantOrgContext.Provider>
    </div>
  );
};

export default App;


