import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";
import { CalendarPMS, PropertyList, Job, ReservationHoverInfoResponse } from '../../api/type';
import { getJobIcon, getImageForBookingChannel, getJobLightIcon } from "../../utils/iconUtils";
import moment from 'moment';

import { fetchReservationHoverInfo } from '../../api/request';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import nightIcon from '../assets/night.svg';
import ellipsis from '../assets/ellipsis.svg';
import ReservationTooltip from "../tooltips/ReservationTooltip";
import SingleJobTooltip from "../tooltips/SingleJobTooltip";
import MultipleJobsTooltip from "../tooltips/MultipleJobsTooltip";

type DayCellProps = {
  date: string;
  calendarPMSMap: Record<string, CalendarPMS | undefined>;
  property: PropertyList | undefined;
  jobList: any[];
  onBookingClick: (property: PropertyList | undefined, booking: CalendarPMS, pageNumber: number) => void;
  onJobClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onReservationClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onJobDetailClick: (job: Job, property: PropertyList | undefined) => void;
  onMoreJobDetailClick: (job: Job[], property: PropertyList | undefined) => void;
  isInRange: boolean;
  newlyAddedReservationId: string | null;
  newlyAddedJobId: string | null;
  pageNumber: number;
};

const DayCell: React.FC<DayCellProps> = ({ date, calendarPMSMap, jobList, property, onBookingClick, onJobClick, onReservationClick, onJobDetailClick, onMoreJobDetailClick, newlyAddedReservationId, isInRange, newlyAddedJobId, pageNumber }) => {
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [hoverTooltipVisible, setHoverTooltipVisible] = useState(false);

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [hoverInfo, setHoverInfo] = useState<ReservationHoverInfoResponse | null>(null);
  const [loadingHoverInfo, setLoadingHoverInfo] = useState(false);

  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [newAddedReservationTooltipVisible, setNewAddedReservationTooltipVisible] = useState(false);
  const [newAddedJobTooltipVisible, setNewAddedJobTooltipVisible] = useState(false);

  const [cachedHoverInfo, setCachedHoverInfo] = useState<{ [key: string]: ReservationHoverInfoResponse | null }>({});

  const booking = calendarPMSMap[date];

  const previousDay = moment(booking?.reservationPMS?.arrivalDate).utc().subtract(1, 'day').format('YYYY-MM-DD');

  const afterDay = moment(booking?.reservationPMS?.departureDate).utc().add(1, 'day').format('YYYY-MM-DD');

  const afterBooking = calendarPMSMap[afterDay];

  const previousBooking = calendarPMSMap[previousDay];

  const normalizedDate = moment(date).format('YYYY-MM-DD');

  const sameDayDeparture = useMemo(() => {
    return (
      booking?.reservationPMS &&
      moment(booking.reservationPMS.arrivalDate).utc().isSame(booking.reservationPMS.departureDate, 'day')
    );
  }, [booking]);

  const isBookingStart = booking?.reservationPMS && moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD') === normalizedDate;
  const isBookingEnd = booking?.reservationPMS && moment(booking.reservationPMS.departureDate).utc().format('YYYY-MM-DD') === normalizedDate && !sameDayDeparture;

  const previousSameDayDeparture = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const departureDate = moment(previousBooking.reservationPMS.departureDate).utc();

    return arrivalDate.isSame(departureDate, 'day');
  }, [booking, calendarPMSMap]);

  const previousBookingExists = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const previousArrivalDate = moment(previousBooking.reservationPMS.arrivalDate).utc();
    const previousDepartureDate = moment(previousBooking.reservationPMS.departureDate).utc();
    const formatArrivalDate = arrivalDate.format('YYYY-MM-DD');

    return arrivalDate.isBetween(previousArrivalDate, previousDepartureDate, 'day') && date === formatArrivalDate;
  }, [booking, calendarPMSMap]);

  const afterBookingExists = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!afterBooking || !afterBooking.reservationPMS) return false;

    const departureDate = moment(booking.reservationPMS.departureDate).utc();
    const afterArrivalDate = moment(afterBooking.reservationPMS.arrivalDate).utc();
    const afterDepartureDate = moment(afterBooking.reservationPMS.departureDate).utc();

    const formatDepatureDate = departureDate.format('YYYY-MM-DD');
    return departureDate.isBetween(afterArrivalDate, afterDepartureDate, 'day') && date === formatDepatureDate;
  }, [booking, calendarPMSMap]);

  const isAfterBookingBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        afterBooking?.reservationPMS?.otaName || ''
      )
    );
  }, [afterBooking]);

  const isPreviousBookingBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        previousBooking?.reservationPMS?.otaName || ''
      )
    );
  }, [previousBooking]);

  const isAnyBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        booking?.reservationPMS?.otaName || ''
      )
    );
  }, [booking]);

  const isAnyReservation = useMemo(() => {
    return (
      ['Direct', 'Booking.com', 'booking.com', 'VRBO', 'Owners Block', 'Airbnb'].includes(
        booking?.reservationPMS?.otaName || ''
      )
    );
  }, [booking]);

  const fetchHoverInfo = async (reservationId: string) => {
    if (cachedHoverInfo[reservationId]) {
      setHoverInfo(cachedHoverInfo[reservationId]);
      setLoadingHoverInfo(false);
      return;
    }
    try {
      setLoadingHoverInfo(true);
      const data = await fetchReservationHoverInfo(reservationId);
      setHoverInfo(data);
      setCachedHoverInfo(prev => ({ ...prev, [reservationId]: data }));
    } catch (error) {
      console.error('Error fetching hover info:', error);
    } finally {
      setLoadingHoverInfo(false);
    }
  };

  useEffect(() => {
    if (tooltipVisible && booking?.reservationPMS?.id) {
      fetchHoverInfo(booking.reservationPMS.id);
    }
  }, [tooltipVisible, booking?.reservationPMS?.id, fetchHoverInfo]);

  useEffect(() => {
    if (
      booking &&
      booking.reservationPMS?.id &&
      booking.reservationPMS.id === newlyAddedReservationId &&
      date === moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD')
    ) {
      setNewAddedReservationTooltipVisible(true);
      const timer = setTimeout(() => {
        setNewAddedReservationTooltipVisible(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [newlyAddedReservationId, booking, date]);

  const openBookingSidebar = (property: PropertyList | undefined, booking: CalendarPMS) => {
    if (booking) {
      onBookingClick(property, booking, pageNumber);
    }
  };

  const openCreateJob = (property: PropertyList | undefined, date: string) => {
    onJobClick(property, date, pageNumber);
  };

  const openCreateReservation = (property: PropertyList | undefined, date: string) => {
    onReservationClick(property, date, pageNumber);
  };

  const openJobDetailSidebar = (job: Job, property: PropertyList | undefined) => {
    if (job) {
      onJobDetailClick(job, property);
    }
  };

  const openMoreDetailSidebar = (jobList: Job[], property: PropertyList | undefined) => {
    if (jobList) {
      onMoreJobDetailClick(jobList, property);
    }
  }

  const handleClick = useCallback(() => {
    setShowAddOptions(true);
  }, []);

  const handleLeave = useCallback(() => {
    setShowAddOptions(false);
  }, []);

  const handleCellEnter = useCallback(() => {
    if ((showAddOptions === false && booking?.reservationPMS) || (showAddOptions === false && jobList.length > 0)) {
      setHoverTooltipVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        handleClick();
      }, 400);
      hoverTimeoutRef.current = timeoutId;
    }
  }, [showAddOptions, booking, jobList, handleClick]);

  const handleCellLeave = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    handleLeave();
    setHoverTooltipVisible(false);
  }, [handleLeave]);

  const handleMouseEnter = useCallback(() => {
    setTooltipVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  return (
    <td className={`table-day-cell  ${isInRange ? '' : 'day-cell-disabled'}
    ${((previousBooking?.reservationPMS && booking?.reservationPMS) ? '' : '')} `}
      onMouseEnter={isInRange ? handleCellEnter : undefined}
      onMouseLeave={isInRange ? handleCellLeave : undefined}
      onClick={isInRange ? handleClick : undefined}
    >
      <div className="day-cell-container">
        {booking && booking.reservationPMS ? (
          <>
            {jobList.length > 0 && (
              <div className={`jobs`}>
                {jobList.length === 1 ? (
                  <Tippy content={
                    <SingleJobTooltip
                      job={jobList[0]}
                      property={property}
                      onClose={() => setTooltipVisible(false)}
                    />
                  } placement="auto" delay={[200, 0]} >
                    <div
                      key={0}
                      className="job-icon"
                      onClick={() => openJobDetailSidebar(jobList[0], property)}
                    >
                      <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                    </div>
                  </Tippy>
                ) : (
                  jobList.length > 1 && (
                    <>
                      {jobList.slice(0, 1).map((job, index) => (
                        <Tippy content={
                          <MultipleJobsTooltip
                            jobs={jobList}
                            property={property}
                            onClose={() => setTooltipVisible(false)}
                          />
                        } placement="auto" delay={[200, 0]} >
                          <div
                            key={index}
                            className="job-icon"
                            onClick={() => openMoreDetailSidebar(jobList, property)}
                          >
                            <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                          </div>
                        </Tippy>
                      ))}
                      <Tippy content={
                        <MultipleJobsTooltip
                          jobs={jobList}
                          property={property}
                          onClose={() => setTooltipVisible(false)}
                        />
                      } placement="auto" delay={[200, 0]} >
                        <div className="more">
                          <span className="more-jobs">{jobList.length - 1}</span>
                        </div>
                      </Tippy>
                    </>
                  )
                )}
              </div>
            )}
          </>
        ) : (
          jobList.length > 0 && (
            <div className={`jobs ${jobList.length > 2 ? 'width-90' : jobList.length > 1 ? 'width-50' : ''}`}>
              {jobList.length === 1 ? (
                <Tippy content={
                  <SingleJobTooltip
                    job={jobList[0]}
                    property={property}
                    onClose={() => setTooltipVisible(false)}
                  />
                } placement="auto" delay={[200, 0]} >
                  <div
                    className="job-icon"
                    onClick={() => openJobDetailSidebar(jobList[0], property)}
                  >
                    <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                  </div>
                </Tippy>
              ) : (
                <>
                  {jobList.slice(0, jobList.length === 3 ? 3 : 2).map((job, index) => (
                    <Tippy content={
                      <MultipleJobsTooltip
                        jobs={jobList}
                        property={property}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        key={index}
                        className="job-icon"
                        onClick={() => openMoreDetailSidebar(jobList, property)}
                      >
                        <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                      </div>
                    </Tippy>
                  ))}
                  {jobList.length > 3 && (
                    <>
                      <Tippy content={
                        <MultipleJobsTooltip
                          jobs={jobList}
                          property={property}
                          onClose={() => setTooltipVisible(false)}
                        />
                      } placement="auto" delay={[200, 0]} >
                        <div
                          className="job-icon"
                          onClick={() => openMoreDetailSidebar(jobList, property)}
                        >
                          <img src={ellipsis} alt='ellipsis-icon' />
                        </div>
                      </Tippy>
                      <Tippy content={
                        <MultipleJobsTooltip
                          jobs={jobList}
                          property={property}
                          onClose={() => setTooltipVisible(false)}
                        />
                      } placement="auto" delay={[200, 0]} >
                        <div className="more">
                          <span className={`${!showAddOptions ? 'more-jobs' : ''}`}>{jobList.length - 2}</span>
                        </div>
                      </Tippy>
                    </>
                  )}
                </>
              )}
            </div>

          )
        )}

        {booking ? (
          booking.reservationPMS ? (
            <>
              {isAnyBlock ? (
                <>
                  {previousSameDayDeparture && (
                    <Tippy content={
                      <ReservationTooltip
                        booking={previousBooking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-end-and-manual'
                          : 'booking-end-and-job'
                          }`}
                      >
                      </div>
                    </Tippy>
                  )}

                  {afterBookingExists && (
                    <Tippy content={
                      <ReservationTooltip
                        booking={afterBooking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        className={`${isAfterBookingBlock
                          ? 'manual-block position-absolute'
                          : 'booking position-absolute'
                          }`}
                      >
                      </div>
                    </Tippy>
                  )}

                  {previousBookingExists && (
                    <Tippy content={
                      <ReservationTooltip
                        booking={previousBooking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-block position-absolute'
                          : 'booking position-absolute'
                          }`}
                      >
                      </div>
                    </Tippy>
                  )}
                  <Tippy content={
                    <ReservationTooltip
                      booking={booking}
                      property={property}
                      hoverInfo={hoverInfo}
                      loadingHoverInfo={loadingHoverInfo}
                      onClose={() => setTooltipVisible(false)}
                    />
                  } placement="auto" delay={[200, 0]} >
                    <div
                      className={`manual-block position-absolute z-index-1 
    ${sameDayDeparture ? 'booking-same-day' :
                          isBookingStart ?
                            (jobList.length > 0
                              ? (moment(date).endOf('month').isSame(date, 'day') ? 'job-and-booking-start width-65' : 'job-and-booking-start width-80')
                              : (moment(date).endOf('month').isSame(date, 'day') ? 'booking-start width-50' : 'booking-start'))
                            : ''} 
    ${isBookingEnd ? (jobList.length === 0 ? 'booking-end' : 'booking-end') : ''}`
                      }
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => openBookingSidebar(property, booking)}
                    ></div>

                  </Tippy>
                </>
              ) : (
                <>
                  {previousSameDayDeparture && (
                    <Tippy content={
                      <ReservationTooltip
                        booking={previousBooking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-end-and-manual'
                          : 'booking-end-and-job'
                          }`}
                      >
                      </div>
                    </Tippy>
                  )}

                  {previousBookingExists && (
                    <Tippy content={
                      <ReservationTooltip
                        booking={previousBooking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    } placement="auto" delay={[200, 0]} >
                      <div
                        className={`${isPreviousBookingBlock
                          ? 'manual-block position-absolute'
                          : 'booking position-absolute'
                          }`}
                      >
                      </div>
                    </Tippy>
                  )}

                  <Tippy
                    content={
                      <ReservationTooltip
                        booking={booking}
                        property={property}
                        hoverInfo={hoverInfo}
                        loadingHoverInfo={loadingHoverInfo}
                        onClose={() => setTooltipVisible(false)}
                      />
                    }
                    placement="auto"
                    delay={[200, 0]}
                  >
                    <div
                      className={`booking position-absolute ${sameDayDeparture
                        ? (jobList.length > 0 ? 'job-and-booking-start width-80 booking-same-day' : 'booking-same-day')
                        : isBookingStart
                          ? (jobList.length > 0
                            ? (moment(date).endOf('month').isSame(date, 'day') ? 'job-and-booking-start width-50' : 'job-and-booking-start width-80')
                            : (moment(date).endOf('month').isSame(date, 'day') ? 'booking-start width-50' : 'booking-start'))
                          : ''} 
      ${isBookingEnd
                          ? (jobList.length === 0 ? 'booking-end' : 'booking-end')
                          : ''
                        }`}
                      onClick={() => openBookingSidebar(property, booking)}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isBookingStart && (
                        <div className="booking-start-content">
                          <div className="circle-image">
                            <img
                              src={getImageForBookingChannel(
                                booking.reservationPMS.otaName
                                  ? booking.reservationPMS.otaName
                                  : ''
                              )}
                              alt="Guest"
                            />
                          </div>
                          <div className="booking-details">
                            <p className="guest-name">
                              {(() => {
                                const fullName = booking.reservationPMS.guestFullName || '';
                                const nameParts = fullName.split(' ');
                                var firstName = nameParts[0];
                                const lastNameInitial = nameParts[1]
                                  ? `${nameParts[1][0]}.`
                                  : '';
                                var firstNameModified = '';
                                if (firstName.length > 6 && jobList.length > 0) {
                                  firstNameModified = `${firstName.slice(0, 6)}`;
                                  return `${firstNameModified} `;
                                } else {
                                  return `${firstName} ${lastNameInitial}`;
                                }
                              })()}
                            </p>
                            <p className="guest-count-label">
                              {booking.reservationPMS.totalGuests} Guests
                            </p>
                            <p className="stay-details">{booking.reservationPMS.totalNights} Nights</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tippy>

                </>
              )}

              {(previousBookingExists && showAddOptions) && (
                <div className={`add-options ${jobList.length >= 2 ? 'z-index-10' : 'z-index-10'}`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                </div>
              )}

              {(!isBookingEnd && showAddOptions && !previousBookingExists) && (
                <div className={`add-options ${jobList.length >= 2 ? 'z-index-10' : 'z-index-10'}`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                </div>
              )}

              {(isBookingEnd && showAddOptions && !previousBookingExists) && (
                <div className={`add-options`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                  <div className="separator">
                    <span>or</span>
                  </div>
                  <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
                </div>
              )}
            </>

          ) : (
            <>
              {showAddOptions ? (
                <div className={`add-options`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                  <div className="separator">
                    <span>or</span>
                  </div>
                  <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
                </div>
              ) : (

                <div className="availability">
                  <p className="price">${Math.round(booking.price)}</p>
                  <p className="stay">
                    <span className="mx-half-right">
                      <img src={nightIcon} alt="night-icon" height={8} width={8} />
                    </span>
                    {booking.minStay}
                  </p>
                </div>
              )}

            </>
          )
        ) : (
          <>
            {showAddOptions ? (
              <div className={`add-options`}>
                <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                <div className="separator">
                  <span>or</span>
                </div>
                <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
              </div>
            ) : (
              <div className="availability">
                <p className="stay">
                  <img src={nightIcon} alt="night-icon" height={8} width={8} />
                </p>
              </div>
            )}
          </>
        )}

        {newAddedReservationTooltipVisible && newlyAddedReservationId && (
          <div className="hover-tooltip">
            <p>New Reservation</p>
          </div>
        )}

        {newAddedJobTooltipVisible && (
          <div className="hover-tooltip">
            <p>New Job</p>
          </div>
        )}

      </div>
    </td>
  );
};

export default React.memo(DayCell);